import { takeEvery, put, call, fork, all } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import {
  TV_CODE_LOAD,
  tvCodeLoadSuccess,
  TV_CHECK_CODE,
  tvCheckCodeSuccess,
  LOGIN_CHECK,
  loginCheckSuccess,
  REFRESH_TOKEN,
  LOGIN_PROFILE_LOAD,
  loginProfileLoadSuccess,
  LOGIN_CREATE_CUSTOMER,
  loginCreateCustomerSuccess,
  FORGOT_SEND_OTP,
  forgotSendOtpSuccess,
  forgotSendOtpError,
  RESET_PASS,
  resetPassSuccess,
  resetPassError,
  BIZ_LOGIN,
  bizLoginSuccess,
  bizLoginError
} from './actions';
import { setError } from '../../redux-root/actions';

export function* tvCodeLoad() {
  try {
    const tvRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d')
    };

    const tvData = yield call(Client.postData, '/devices/otp', tvRequest);

    yield put(tvCodeLoadSuccess(tvData.data));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* tvCheckCode(action) {
  const { payload } = action;

  try {
    const tvRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      platformType: 'Tizen',
      pin: payload
    };

    const tvData = yield call(Client.postData, '/devices/linkstatus', tvRequest);

    if (tvData.data && tvData.data.isLinked) {
      yield put(tvCheckCodeSuccess(tvData.data));
    } else {
      // SET ERROR
    }
  } catch (error) {
    // console.log(error);
  }
}

export function* loginCheck(action) {
  const { payload, auto } = action;

  Client.setToken('Bearer', payload);

  let platformtype;

  try {
    if (window.tizen) {
      platformtype = 'Tizen';
    }

    // LG PLATFORMTYPE CHANGE - TODO

    if (!auto) {
      const deviceRequest = {
        requestId: uuidv4(),
        deviceId: localStorage.getItem('_d')
      };

      yield call(Client.postData, '/me/devices', deviceRequest);
    }

    const profileData = yield call(Client.getData, `/me${ platformtype ? `?platformtype=${platformtype}` : "" }`);

    yield put(loginCheckSuccess(profileData.data))
  } catch (error) {
    if (error.status === 401 && error.data && error.data.message && error.data.message === 'The incoming token has expired') { // BIZ SESSION TMEOUT
      localStorage.removeItem('_td');
    }

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* profileLoad(action) {
  let platformtype;

  try {
    if (window.tizen) {
      platformtype = 'Tizen';
    }

    // let [profileData, parentalData, channelData, channelDemandData] = yield all([
    //   call(Client.getData, `/me${ platformtype ? `?platformtype=${platformtype}` : "" }`),
    //   call(Client.getData, '/me/parentalcontrol'),
    //   call(Client.getData, '/me/channels?offset=0&limit=200'),
    //   call(Client.getData, '/me/channelondemands?offset=0&limit=200')
    // ]);
    let [profileData, channelData, channelDemandData] = yield all([
      call(Client.getData, `/me${ platformtype ? `?platformtype=${platformtype}` : "" }`),
      call(Client.getData, '/me/channels?offset=0&limit=200'),
      call(Client.getData, '/me/channelondemands?offset=0&limit=200')
    ]);

    let fullChannel = [...action.freeChannel, ...channelData.data];

    fullChannel = fullChannel
      .filter((c, index, self) => index === self.findIndex((t => t.number === c.number)))
      .sort((a, b) => {
        if (a.number > b.number) {
          return 1;
        }

        return -1;
      });

    // profileData.data.parental = parentalData.data;
    profileData.data.channel = fullChannel;
    profileData.data.channelondemand = channelDemandData.data;

    yield put(loginProfileLoadSuccess(profileData.data))
  } catch (error) {
    // console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* createCustomer(action) {
  const { payload } = action;

  try {
    const createRequest = {
      requestId: uuidv4(),
      mobilePhone: payload
    };

    const createData = yield call(Client.postData, '/customers', createRequest);

    yield put(loginCreateCustomerSuccess())
  } catch (error) {
    // console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

export function* refreshToken(action) {
  const { payload } = action;

  Client.setToken('Bearer', payload);
}

export function* forgotSendOtp(action) {
  const { payload } = action;

  try {
    const otpRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      challengeType: 'ResetCustomerPassword',
      email: payload
    };

    yield call(Client.postData, '/customers/challenge', otpRequest);

    yield put(forgotSendOtpSuccess())
  } catch (error) {
    // console.log(error);

    yield put(forgotSendOtpError());
  }
}

export function* resetPass(action) {
  const { payload } = action;

  try {
    const resetRequest = {
      requestId: uuidv4(),
      deviceId: localStorage.getItem('_d'),
      ...payload
    };

    yield call(Client.postData, '/customers/resetsecret', resetRequest);

    yield put(resetPassSuccess());
  } catch (error) {
    // console.log(error);

    yield put(resetPassError());
  }
}

export function* bizLogin(action) {
  const { payload } = action;

  try {
    const bizRequest = {
      requestId: uuidv4(),
      username: payload
    };

    const bizData = yield call(Client.postData, '/auth/login', bizRequest);

    if (bizData && bizData.status === 200) {
      const nowDateTime = new Date();

      const bizProfileData = yield call(Client.getData, `/me/public?code=${payload}`, bizRequest);

      if (bizProfileData && bizProfileData.status === 200) {
        const bizFinalData = {
          ...bizData.data,
          ...bizProfileData.data,
          expiresIn: new Date(nowDateTime.getTime() + bizData.data.expiresIn * 1000)
        };

        yield put(bizLoginSuccess(bizFinalData));
      } else {
        yield put(bizLoginError());
      }
    } else {
      yield put(bizLoginError());
    }
  } catch (error) {
    // console.log(error);

    yield put(bizLoginError());
  }
}

function* watchTVCodeLoad() {
  yield takeEvery(TV_CODE_LOAD, tvCodeLoad);
}

function* watchCheckCode() {
  yield takeEvery(TV_CHECK_CODE, tvCheckCode);
}

function* watchLoginCheck() {
  yield takeEvery(LOGIN_CHECK, loginCheck);
}

function* watchRefreshToken() {
  yield takeEvery(REFRESH_TOKEN, refreshToken);
}

function* watchLoginProfileLoad() {
  yield takeEvery(LOGIN_PROFILE_LOAD, profileLoad);
}

function* watchLoginCreate() {
  yield takeEvery(LOGIN_CREATE_CUSTOMER, createCustomer);
}

function* watchForgotSendOtp() {
  yield takeEvery(FORGOT_SEND_OTP, forgotSendOtp);
}

function* watchResetPass() {
  yield takeEvery(RESET_PASS, resetPass);
}

function* watchBizLogin() {
  yield takeEvery(BIZ_LOGIN, bizLogin);
}

export function* loginSaga() {
  yield fork(watchTVCodeLoad);
  yield fork(watchCheckCode);
  yield fork(watchLoginCheck);
  yield fork(watchRefreshToken);
  yield fork(watchLoginProfileLoad);
  yield fork(watchLoginCreate);
  yield fork(watchForgotSendOtp);
  yield fork(watchResetPass);
  yield fork(watchBizLogin);
}
