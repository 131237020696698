import { takeEvery, put, call, fork } from 'redux-saga/effects';
import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import { HOME_LOAD, homeLoadSuccess } from './actions';
import { setError } from '../../redux-root/actions';
import urnJson from '../../config/urn-list.json';

const getAllData = async (list) => {
  const newList = list.map(lane => {
    if (lane.items && lane.items.length > 0) {
      return lane;
    }

    if (lane.itemRuleType === 'Urn') {
      const urnAction = urnJson.contentList.filter(item => item.urn === lane.itemRule)[0];

      // if (urnAction.tvDisable && window.tizen) {
      //   return lane;
      // }

      if (urnAction.url) {
        return Client.getData(urnAction.url).then(res => {
          if (res.data.movies && res.data.showEpisodes) {
            res.data = [...res.data.movies, ...res.data.showEpisodes];
          } else if (res.data.packages) {
            let newPackages = res.data.packages.filter(p => p.targetType === 'Page');
            newPackages = newPackages.map(p => {
              p.title = p.name;

              return p;
            });

            res.data = newPackages;
          }

          if (res.data && res.data.length > 0) {
            if (res.data[0].startedDate) {
              res.data.sort((a, b) => {
                if (new Date(a.startedDate) > new Date(b.startedDate)) {
                  return -1;
                }

                return 1;
              });
            } else if (res.data[0].modifiedDate) {
              res.data.sort((a, b) => {
                if (new Date(a.startedDate) > new Date(b.startedDate)) {
                  return 1;
                }

                return -1;
              });
            }

            lane.items = res.data;
          }

          return lane;
        });
      }

      return lane;
    }

    return lane;
  });

  return Promise.all(newList);
}

export function* homeLoad(action) {
  const { profileData } = action;

  try {
    let homeData;
    let favouriteData = {
      channel: [],
      movie: [],
      show: []
    };

    if (localStorage.getItem('_r')) {
      // const favouriteChannelData = yield call(Client.getData, '/me/playlists/handles/watchlater/channels');
      // const favouriteMovieData = yield call(Client.getData, '/me/playlists/handles/watchlater/movies');
      // const favouriteShowData = yield call(Client.getData, '/me/playlists/handles/watchlater/shows');

      // favouriteData.channel = favouriteChannelData.data;
      // favouriteData.movie = favouriteMovieData.data;
      // favouriteData.show = favouriteShowData.data;

      if (profileData.pageId) {
        homeData = yield call(Client.getData, `/pages/${profileData.pageId}`);
      } else {
        homeData = yield call(Client.getData, '/pages/handles/home-v4');
      }

      homeData.data.collections = homeData.data.collections.map((col, index) => {
        if (index > 0 && col.items.length > 0) {
          if (col.items[0].channelOnDemandId) {
            col.items = col.items.filter(i => {
              if (profileData && profileData.channelondemand && profileData.channelondemand.find(c => c.id === i.channelOnDemandId)) {
                return true;
              }

              return false;
            });
          } else {
            col.items = col.items.filter(i => {
              if (i.isFree  || (profileData && profileData.channel &&profileData.channel.find(c => c.id === i.channelId))) {
                return true;
              }

              return false;
            });
          }
        }

        return col;
      });

      homeData.data.collections = homeData.data.collections.filter(col => col !== undefined);

      homeData.data.collections = yield call(getAllData, homeData.data.collections);
    } else {
      homeData = yield call(Client.getData, '/pages/handles/free-home-v4');
    }

    yield put(homeLoadSuccess(homeData.data, favouriteData));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchHomeLoad() {
  yield takeEvery(HOME_LOAD, homeLoad);
}

export function* homeSaga() {
  yield fork(watchHomeLoad);
}
