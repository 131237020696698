import { takeEvery, put, call, fork, all } from 'redux-saga/effects';
// import { v4 as uuidv4 } from 'uuid';

import { Client } from '../../utils/client';
import { DETAILS_LOAD, detailsLoadSuccess } from './actions';
import { setError } from '../../redux-root/actions';

export function* detailsLoad(action) {
  let url = '/shows';

  if (action.isMovie) {
    url = '/movies';
  }

  try {
    let historyData = {};
    let favouriteData = {};
    let detailsData = {};

    if (localStorage.getItem('_r')) {
      const nowDate = new Date();

      if (action.isMovie) {
        let historyMovieData;
        let favouriteMovieData;

        [detailsData, historyMovieData, favouriteMovieData] = yield all([
          call(Client.getData, `${url}/${action.id}`),
          // call(Client.getData, `/me/history/movies?t=${nowDate.toISOString()}`),
          // call(Client.getData, '/me/playlists/handles/watchlater/movies')
        ]);

        // historyData.movie = historyMovieData.data;
        // favouriteData.movie = favouriteMovieData.data;
        historyData.movie = [];
        favouriteData.movie = [];
      } else {
        let historyShowData;
        let favouriteShowData;

        [detailsData, historyShowData, favouriteShowData] = yield all([
          call(Client.getData, `${url}/${action.id}`),
          // call(Client.getData, `/me/history/shows?t=${nowDate.toISOString()}`),
          // call(Client.getData, '/me/playlists/handles/watchlater/shows')
        ]);

        // historyData.show = historyShowData.data;
        // favouriteData.show = favouriteShowData.data;
        historyData.show = [];
        favouriteData.show = [];
      }
    } else {
      detailsData = yield call(Client.getData, `${url}/${action.id}`);
    }

    let { data } = detailsData;

    if (action.isMovie) {
      data.targetType = 'Movie';
    } else {
      data.targetType = 'Show';
    }

    let demandId = '';
    let genreSearch = '';

    if (data) {
      if (data.channelOnDemandId) {
        demandId = data.channelOnDemandId;
      }

      if (data.genres && data.genres.length > 0) {
        genreSearch = `&genre=${encodeURIComponent(data.genres[0])}`;
      }
    }

    // const [packageData, moreData] = yield all([
    const [moreData] = yield all([
      // call(Client.getData, `/packages/?offset=0&limit=200&iscurrent=true&channelondemandid=${demandId}`),
      call(Client.getData, `${url}/?offset=0&limit=14&channelondemandid=${demandId}${genreSearch}`)
    ]);

    // data.packages = packageData.data;
    data.packages = [];

    yield put(detailsLoadSuccess(data, moreData.data, favouriteData, historyData));
  } catch (error) {
    console.log(error);

    yield put(setError('General Errors', `It's not you. It's us. We are working to fix this...`, error));
  }
}

function* watchDetailsLoad() {
  yield takeEvery(DETAILS_LOAD, detailsLoad);
}

export function* detailsSaga() {
  yield fork(watchDetailsLoad);
}
