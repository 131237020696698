import React, { useState, useEffect, useRef } from 'react'
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import Lottie from "lottie-react";
import _ from 'lodash';
import styles from './index.module.css';
import Text from '../Text';
import NavigationMore from '../NavigationMore/index'
import unfoldIcon from '../../assets/icons/mobileMenu/unfold.svg';
import searchIcon from '../../assets/icons/mobileMenu/search.svg';
import closeIcon from '../../assets/icons/mobileMenu/close.svg';
import logIcon from '../../assets/icons/logo/ui_logo_cast_1.svg';
import homeIcon from '../../assets/icons/mobileMenu/home.svg';
import liveTvIcon from '../../assets/icons/mobileMenu/livetv.svg';
import onDemandIcon from '../../assets/icons/mobileMenu/explore.svg';
import guideIcon from '../../assets/icons/mobileMenu/guide.svg';

import { useNavigate, useLocation } from "react-router-dom";
import { toggleMenu, setModal } from '../../redux-root/actions';
import gtmHelper from '../../utils/gtmHelper';

import searchJson from '../../assets/json/animation/menu/search.json';
import homeJson from '../../assets/json/animation/menu/home.json';
import liveTVJson from '../../assets/json/animation/menu/livetv.json';
import exploreJson from '../../assets/json/animation/menu/ondemand.json';
import guideJson from '../../assets/json/animation/menu/channelguide.json';
import moreJson from '../../assets/json/animation/menu/more.json';

let gtmObj = {};

function NavigationMobileMenu(props) {
  const { authenticated, profileData, channelData, hashUserId } = props;

  const [showMenu, setShowMenu] = useState(false)
  const navigate = useNavigate();

  const menuList = [
    { id: 'home', name: "Home", icon: homeIcon, path: '/' },
    { id: 'liveTV', name: 'Live TV', icon: liveTvIcon, path: '/livetv' },
    { id: 'explore', name: 'Explore', icon: onDemandIcon, path: '/ondemand' },
    { id: 'guide', name: 'Guide', icon: guideIcon, path: '/guide' }]

  const clickUnfold = () => {
    setShowMenu(true)
  }

  const clickClose = () => {
    setShowMenu(false)
  }

  const menuClick = (index, data) => {
    if (index !== undefined) {
      setShowMenu(false)

      if (data.path === '/livetv') {
        if (authenticated) {
          navigate(`/channel/${profileData.channel[0].id}`);
        } else {
          navigate(`/channel/${channelData.freeChannel[0].id}`);
        }
      } else {
        navigate(data.path);
      }
    }
  }

  const clickSearch = () => {
    navigate('/search')
  }

  const logClick = () => {
    if (authenticated) {
      gtmObj.category = 'watchcast-logout';
      gtmObj.userId = hashUserId;
      
      gtmHelper({
        ...gtmObj,
        section: 'authentication',
        subSection: 'are-you-sure-you-want-to-logout?',
        action: 'logout-pop-up-load',
        label: 'logout-pop-up-load',
        componentType: 'logout-pop-up-load'
      });

      props.setModal(
        'Log out',
        'Are you sure you want to logout?',
        'Yes',
        () => props.logoutClick()
      );
    } else {
      navigate('/login');
    }
  }

  const path = window.location.pathname;

  return (
    <div>
      <div className={styles['wrapper']}>
        {!showMenu && <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div><img onClick={() => clickUnfold()} className={styles['unfoldIcon']} src={unfoldIcon} /></div>
          <div className={styles['log-wrapper']}><img className={styles['log']} src={logIcon} /></div>
          {/*<div><img onClick={() => clickSearch()} className={styles['search']} src={searchIcon} /></div>*/}
        </div>}

      </div>
      {showMenu && <div className={styles['showMenu']}>
        <div style={{ display: 'flex' }}>
          <div className={styles['close']}><img onClick={() => clickClose()} src={closeIcon} /></div>
          {/*<div style={{ paddingTop: '61px', paddingRight: '11px' }}>
            <button
              className={styles['logOut']}
              onClick={logClick}
            >
              <Text type="lb28">
                {
                  authenticated ?
                    'Logout'
                    :
                    'Login'
                }
              </Text>
            </button>
          </div>*/}
        </div>
        {menuList.map((data, index) => {
          return <div key={index} onClick={() => menuClick(index, data)} className={styles['iconLocation']}>
            <div className={styles['icon']}><img src={data.icon} /></div>
            <div className={path === data.path ? styles['iconNameActive'] : styles['iconName']}>{data.name}</div>
          </div>
        })}
        {/*<NavigationMore setShowMenu={setShowMenu} />*/}
      </div>}

    </div>
  );
}


const mapStateToProps = state => ({
  collapsed: state.menu.menuCollapsed,
  streamVod: state.app.streamVod,
  prevPath: state.app.prevPath,
  profileData: state.app.profileData,
  authenticated: state.app.authenticated,
  channelData: state.app.channelData,
  hashUserId: state.app.hashUserId
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
      toggleMenu,
      setModal
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(NavigationMobileMenu);
